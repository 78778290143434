import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class EmployeeListView extends BaseListView {

  renderTopPanelLeftItems() {
    const that = this

    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        that.onSearch({ ...that.props.search_data, last_name: event.target.value })
      }
    }

    return [
      super.renderTopPanelLeftItems(),

      <span class="d-none d-md-inline-block me-2">
        <input class="form-control me-2 p-0 px-1" placeholder="Поиск" style={{width: "200px"}} onKeyDown={handleKeyDown}></input>
      </span>,
    ]
  }
}


export default wrapView(EmployeeListView, (state) => {
  return stateToProps(state, 'employee')
})
